<template>
    <div class="wrap" style="padding-bottom: 100px;">
        <Aside v-if="asideStatus" @closeAside="asideStatus = false" />
        <header class="header mo_header">
            <div class="header_box">
                <div class="logo">
                    <a>
                        <img src="/media/images/icon_back_24.svg" @click="linkPlayer()">
                        <h2 style="margin-bottom:0px;">알림함</h2>
                    </a>
                </div>
                <div class="right_menu">
                    <div class="alarm_box">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"
                             @click="mobileAlarm">
                            <path
                                d="M9.02759 19.7773C9.02759 20.5181 9.46002 21.9996 11.1897 21.9996C12.9195 21.9996 13.3519 20.5181 13.3519 19.7773"
                                stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M2.00024 18.666H20.3786" stroke="white" stroke-width="2" stroke-linecap="round"
                                  stroke-linejoin="round" />
                            <path
                                d="M3.62183 18.1113V10.3335C3.62183 7.92613 5.13534 3.11133 11.1894 3.11133C17.2434 3.11133 18.7569 8.2965 18.7569 10.8891V18.1113"
                                stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                            <ellipse v-if="$store.state.noticeCount !== 0" cx="17.6756" cy="6.44444" rx="4.32431" ry="4.44444"
                                     fill="#FF1150" />
                        </svg>
                    </div>
                    <div class="toggle">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"
                             @click="showAside">
                            <g clip-path="url(#clip0_2231_20993)">
                                <path d="M24 20L6 20L6 18L24 18L24 20ZM24 13L12 13L12 11L24 11L24 13ZM24 6L6 6L6 4L24 4L24 6Z"
                                      fill="white" />
                            </g>
                            <defs>
                                <clipPath id="clip0_2231_20993">
                                    <rect width="24" height="24" fill="white" transform="translate(24 24) rotate(180)" />
                                </clipPath>
                            </defs>
                        </svg>
                    </div>
                </div>
            </div>
        </header>
        <section class="main notifiacation">
            <div class="main_box">
                <h1>알림함</h1>
                <div class="notifiacation_content">
                    <div v-if="topList.length === 0 && noticeList === 0">
                        <div class="empty_area">
                            <img src="/media/img/empty_alarm.png" alt="" />
                            <p>
                                새로운 알림이 없습니다.
                            </p>
                        </div>
                    </div>
                    <div v-else>
                        <template v-for="(item, i) in topList">
                            <ul style="margin-bottom: 0px" :key="'common' + i">
                                <li>
                                    <p>공통</p>
                                    <a @click="linkNoticeRead(item)">
                                        <div>
                                            <h3 v-html="item.commonNoticeTitle"></h3>
                                            <p style="margin-bottom: 0px" class="content-box" v-html="item.commonNoticeContents"></p>
                                        </div>
                                    </a>
                                </li>
                                <li>
                                    <p style="margin-bottom: 0px">
                                        {{ dateTime(item.registDate) }}
                                    </p>
                                </li>
                            </ul>
                        </template>
                        <template v-for="(item, index) in noticeList">
                            <ul style="margin-bottom: 0px" :class="{ on: item.noticeReadYn === 'N' }" :key="index">
                                <li v-if="item.status === 'N'">
                                    <p style="margin-bottom: 0px" id="attr_txt">MY</p>
                                    <div @click="linkNoticeRead(item)">
                                        <h3 v-html="item.noticeTitle"></h3>
                                        <p style="margin-bottom: 0px" class="content-box" v-html="item.noticeContent"></p>
                                    </div>
                                </li>
                                <li v-else>
                                    <p style="margin-bottom: 0px">공통</p>
                                    <a @click="linkNoticeRead(item)">
                                        <div>
                                            <h3 v-html="item.commonNoticeTitle"></h3>
                                            <p style="margin-bottom: 0px" class="content-box" v-html="item.commonNoticeContents"></p>
                                        </div>
                                    </a>
                                </li>
                                <li>
                                    <p style="margin-bottom: 0px">
                                        {{ dateTime(item.registDate) }}
                                    </p>
                                </li>
                            </ul>
                        </template>
                    </div>
                </div>
            </div>
            <infinite-loading @infinite="infiniteHandler" spinner="waveDots" v-if="checkList">
                <div slot="no-more"></div>
                <div slot="no-results"></div>
                <div slot="error"></div>
            </infinite-loading>
        </section>
    </div>
</template>
<script>
import { defineComponent } from "@vue/composition-api";
import InfiniteLoading from 'vue-infinite-loading';
import Aside from "@/layout/aside/Aside.vue";
import VueCookies from "vue-cookies";
import router from "@/router";
import dayjs from "dayjs";
import axios from "axios";

export default defineComponent({
  data () {
    return {
      limit: 0,
      last: 10,
      count: 0,
      topList: [],
      noticeList: [],
      noticeListData: [],
      asideStatus: false,
      displayCheck: true,
      checkList: false
    };
  },
  components: {
    Aside,
    InfiniteLoading
  },
  created () {
    this.getCommonNoticeList();
    this.getNoticeList();
  },
  mounted () {
    this.noticeTimeCheck();
  },
  methods: {
    noticeTimeCheck () {
      const userId = VueCookies.get("userId");
      const aToken = VueCookies.get("aToken");
      const headers = { "X-AUTH-TOKEN": aToken };

      axios
        .post("/api/menu/updateNoticeCheckTime", { userId }, { headers })
        .catch(err => {
          console.log(err);
        });
    },
    linkPlayer () {
      const spaceId = VueCookies.get("spaceId");
      if (spaceId != null) {
        router.push({ name: "Player" });
      } else {
        router.push({ name: "Main" });
      }
    },
    linkNoticeRead (item) {
      let noticeId = '';
      item.noticeId ? noticeId = item.noticeId : noticeId = item.commonNoticeId;
      this.$store.commit("setNoticeId", noticeId);
      const userId = VueCookies.get("userId");
      const aToken = VueCookies.get("aToken");
      const headers = { "X-AUTH-TOKEN": aToken };

      axios
        .post("/api/menu/updateNoticeRead", { userId, noticeId }, { headers })
        .then(res => {
          if (res.data.resultCd === '0000') {
            //
            if (Object.keys(item).includes('noticeCategory')) {
              if (item.noticeCategory === 'Question') {
                this.$router.push({ name: 'Question' });
              } else if (item.noticeCategory === 'Event') {
                if (Object.keys(item).includes('linkedId')) {
                  this.$store.commit('setEventId', item.linkedId);
                  this.$router.push({ name: 'EventView' });
                } else {
                  this.$router.push({ name: 'Notice1' });
                }
              } else {
                this.$router.push({ name: 'Notice1' });
              }
            } else {
              this.$router.push({ name: 'Notice1' });
            }
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
    clickPre () {
      history.back();
    },
    dateTime (value) {
      return dayjs(value).format("YY.MM.DD HH:mm");
    },
    getCommonNoticeList () {
      axios
        .post('/api/menu/getTopCommonNoticeList')
        .then(res => {
          const list = res.data.result;
          for (var i in list) {
            list[i].commonNoticeContents = list[i].commonNoticeContents.replaceAll('&lt;', '<').replaceAll('&gt;', '>').replaceAll("&amp;", "&").replaceAll("&#x2F;", "/");
          }
          this.topList = list;
          this.count = this.topList.length;
        })
        .catch(err => {
          console.log(err);
        });
    },
    getNoticeList () {
      const aToken = VueCookies.get("aToken");
      const headers = { "X-AUTH-TOKEN": aToken };
      // const noticeType = 'User';
      const noticeTypeDetail = VueCookies.get("userId");

      axios
        .post("/api/menu/getNoticeList", { noticeTypeDetail }, { headers })
        .then((res) => {
          // console.log(res.data);
          this.total = res.data.result.length;
          this.noticeListData = res.data.result;
          const list = res.data.result;
          for (var i in list) {
            if (list[i].commonNoticeContents) {
              list[i].commonNoticeContents = list[i].commonNoticeContents.replaceAll('&lt;', '<').replaceAll('&gt;', '>').replaceAll("&amp;", "&").replaceAll("&#x2F;", "/");
              list[i].status = 'C';
            } else {
              if (list.noticeContent !== null) {
                list[i].noticeContent = list[i].noticeContent.replaceAll('&lt;', '<').replaceAll('&gt;', '>').replaceAll("&amp;", "&").replaceAll("&#x2F;", "/");
                list[i].status = 'N';
              }
            }
          }
          this.noticeList = list;
          if (this.count >= 0) {
            this.last -= (this.count);
          }
          this.noticeList = res.data.result.slice(this.limit, this.last);

          if (this.count >= 0) {
            this.limit = this.last;
            this.last += 10;
            this.checkList = true;
          }
        });
    },
    infiniteHandler ($state) {
      var list = this.noticeListData;
      var limit = this.limit;
      var last = this.last;

      setTimeout(() => {
        if (list.length <= last) {
          var list1 = list.slice(limit);
        } else {
          list1 = list.slice(limit, last);
        }
        this.noticeList.push(...list1);
        this.limit += 10;
        this.last += 10;
        $state.loaded();
        if (list.length < limit) {
          $state.complete();
        }
      }, 2000);
    },
    showAside () {
      this.asideStatus = true;
    },
    mobileAlarm () {
      router.push({ name: "Notice0" });
    }
  }
});
</script>

<style>
.content-box,
.content-box p {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-height: 22px;
}
</style>
<style src="@/assets/css/content2.css"></style>
<style src="@/assets/css/font.css"></style>
